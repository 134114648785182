<template>
  <v-chart class="chart" :option="option" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { getHeatingPower } from "@/api/api.js";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
export default {
  props: ["data"],
  data() {
    return {
      timer: 0,
      option: {
        legend: {
          type: "plain",
          orient: "vertical",
          right: "10%",
          top: "20%",
          textStyle: {
            color: "#a3fffe",
            fontSize: "16",
          },
          itemHeight: 12,
          itemWidth: 12,
          itemGap: 20,
        },
        tooltip: {
          trigger: "item",
        },
        series: {
          name: "各年龄段人数占比分析：",
          type: "pie",
          radius: [30, 80],
          center: ["30%", "50%"],
          roseType: "area",
          data: [],
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: '{c}'
              }
            }
          },
          left: "0%",
        },
      },
    };
  },
  methods: {
    getData() {
      getHeatingPower().then((res) => {
        this.option.series.data = res.data.data.age_count.data.map((o) => {
          return { name: o.data_name, value: o.data_num }
        });
        return
      });
    }
  },
  mounted() {
    this.getData();
    this.timer = setInterval(this.getData, 6000)
  },
};
</script>
<style scoped></style>
